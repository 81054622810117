import { GetPathFunction } from '@homeproved/shared/feature-localized-routes';
import { Button, SectionTitle } from '@homeproved/shared/ui';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CompanyCarrousel } from '@homeproved/shared/feature-company';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { HomepageContainerWrapper } from '@homeproved/com/feature-homepage';

const CompanySectionWrapper = styled(({ mobile, transparentBG, noTopSpacing, ...restProps }) => (
  <section {...restProps} />
))`
  background-color: transparent;
  margin-top: ${({ noTopSpacing }) => (noTopSpacing ? '0' : '2rem')};
  padding-bottom: ${({ mobile }) => (mobile ? '4rem' : '5rem')};
  padding-top: ${({ mobile, noTopSpacing }) => (noTopSpacing ? 0 : mobile ? '2rem' : '3rem')};
  position: relative;
  z-index: 9;
`;

export const ContentWrapper = styled(({ mobile, noTopSpacing, innerRef, ...restProps }) => (
  <div ref={innerRef} {...restProps} />
))`
  max-width: 118rem;
  margin: auto;
`;

const ButtonWrapper = styled(({ ...restProps }) => <div {...restProps} />)`
  display: flex;
  justify-content: center;
`;

type CompanySectionProps = {
  sectorSlug?: string;
  location?: { lng: number; lat: number };
  transparentBG?: boolean;
  bordered?: boolean;
  noTopSpacing?: boolean;
  getPath: GetPathFunction;
};

export const CompanySection: FC<CompanySectionProps> = ({
  sectorSlug,
  location,
  transparentBG,
  noTopSpacing,
  getPath,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const title = t('app.com.pages.landing.company.recent');
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.xs));

  return (
    <HomepageContainerWrapper>
      <CompanySectionWrapper
        transparentBG={transparentBG}
        noTopSpacing={noTopSpacing}
        mobile={isMobile}
      >
        <ContentWrapper mobile={isMobile} noTopSpacing={noTopSpacing}>
          <SectionTitle textAlign={isMobile ?'center' : 'left'} label={title} lineColor="black" />
          <CompanyCarrousel
            isMobile={isMobile}
            sectorSlug={sectorSlug}
            location={location}
            getComPath={getPath}
          />
        </ContentWrapper>
        <ButtonWrapper>
          <Button
            variant="gradient"
            size="large"
            href={getPath('/sectors')}
          >
            {t('app.com.pages.landing.company.button')}
          </Button>
        </ButtonWrapper>
      </CompanySectionWrapper>
    </HomepageContainerWrapper>
  );
};
