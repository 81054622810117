import React, { FC } from 'react';
import styled from 'styled-components';

export type HomepageContainerProps = {
  children: React.ReactNode;
  background?: string;
  isMobile?: boolean;
  height?: number;
};

const Wrapper = styled(({ background, height,isMobile, ...restProps }) => <div {...restProps} />)`
  position: relative;
  z-index: 10;
  width: 100%;
  display: flex;
  height: ${({ height }) => height + 'px' || '100%'};
  background: ${({ background }) => background || 'transparent'};
  background-size: cover;
  background-position: center;
`;

const Inner = styled.div`
  width: 100%;
  padding: 0 2rem;
`;

export const HomepageContainerWrapper: FC<HomepageContainerProps> = ({
  background,
  height,
  isMobile = false,
  children,
}) => (
  <Wrapper background={background} height={height} isMobile={isMobile}>
    <Inner>{children}</Inner>
  </Wrapper>
);
