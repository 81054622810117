import React, { FC } from 'react';
import styled from 'styled-components';
import { Icons, IconTile, SectionTitle } from '@homeproved/shared/ui';
import { useTranslation } from 'react-i18next';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { HomepageContainerWrapper } from '../container/Container';
import { useLocalizedRoutes } from '@homeproved/com/feature-localized-routes';
import { Sector } from '@homeproved/shared/data-access';
import Link from 'next/link';
import { useCurrentLanguage } from '@homeproved/shared/feature-i18n';

type CategoriesProps = {
  sectors: Sector[] | null;
};

const StyledHomePageCategories = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  overflow: hidden;
`;

const CategoriesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.5rem;
  padding: 1rem 0 1.5rem;
  a {
    text-decoration: none;
    display: flex;
  }
`;

const IconTileWrapper = styled(({ isMobile, isTablet, ...restProps }) => <div {...restProps} />)`
  margin: 0.5rem;
  min-height: 12rem;
  flex: ${({ isMobile, isTablet }) =>
    isMobile
      ? '0 0 calc(50% - 1rem)'
      : isTablet
      ? '0 0 calc(25% - 1rem)'
      : '0 0 calc(12.5% - 1rem)'};

  > * {
    width: 100%;
    height: 100%;
  }
`;
const RotatedIconIconTile = styled(IconTile)`
  div {
    background: none;
  }
  svg {
    transform: rotate(90deg);
  }
`;

export const Categories: FC<CategoriesProps> = ({ sectors }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.xs));
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));

  const currentLanguage = useCurrentLanguage();
  const { getPath } = useLocalizedRoutes();

  if (!sectors || sectors.length === 0) return null;
  return (
    <HomepageContainerWrapper>
      <StyledHomePageCategories>
        <SectionTitle
          label={t('app.com.pages.landing.categories.header')}
          textAlign={isMobile ? 'center' : 'left'}
        />
        <CategoriesWrapper>
          {sectors.slice(0, 7).map(
            ({ data: sector }) =>
              sector.id != null && (
                <IconTileWrapper key={sector.id} isMobile={isMobile} isTablet={isTablet}>
                  <Link
                    href={getPath('/sectors/:sector', {
                      sector: sector.slug.data[currentLanguage],
                    })}
                  >
                    <a
                      href={getPath('/sectors/:sector', {
                        sector: sector.slug.data[currentLanguage],
                      })}
                    >
                      <Box display="flex" width="100%">
                        <IconTile
                          icon={
                            Icons[
                              sector.icon === 'helmet' || sector.icon == null
                                ? 'HELMET_OUTLINE'
                                : sector.icon.toUpperCase()
                            ]
                          }
                          label={sector.name}
                        />
                      </Box>
                    </a>
                  </Link>
                </IconTileWrapper>
              )
          )}
          <IconTileWrapper isMobile={isMobile} isTablet={isTablet}>
            <Link href={getPath('/sectors')}>
              <Box display="flex">
                <RotatedIconIconTile
                  icon={Icons.KEBAB}
                  label={t('app.com.pages.landing.categories.button')}
                />
              </Box>
            </Link>
          </IconTileWrapper>
        </CategoriesWrapper>
      </StyledHomePageCategories>
    </HomepageContainerWrapper>
  );
};
