import React, { FC, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Icons, SvgIcon } from '@homeproved/shared/ui';
import { Box, useTheme } from '@material-ui/core';
import { PlanUid } from '../util/helpers';
import { CompanyData, PlanData, Plan } from '@homeproved/shared/data-access';
import { useLocalizedRoutes } from '@homeproved/pro/feature-localized-routes';
import { useUser } from '@homeproved/shared/feature-auth';

export interface YourPackageProps {
  plan: PlanUid;
  teamPlan: PlanData;
  plans?: Plan[] | undefined;
  isMobile: boolean;
  company: CompanyData;
}

const Wrapper = styled(({ isMobile, plan, ...restProps }) => <div {...restProps} />)`
  background: #fff;
  border-radius: ${({ theme }) => theme.config.defaultBorderRadius};
  padding: 1rem;
  flex-basis: 28rem;
  margin: ${({ isMobile, plan }) => (isMobile ? '0 0 2rem' : plan === 'team' ? '0' : '0 2rem 0 0')};

  ${({ plan, isMobile }) =>
    plan === 'team' &&
    !isMobile &&
    `
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  `};
`;

const Title = styled.div`
  font-family: ${({ theme }) => theme.config.fonts.DancingScript};
  font-size: 2.6rem;
  text-align: center;
  display: table;
  margin: 2rem auto;
  position: relative;

  &:after {
    content: '';
    background: url('./curved-arrow-down.svg') no-repeat;
    width: 2.8rem;
    height: 4.2rem;
    display: block;
    position: absolute;
    top: 50%;
    right: -3rem;
  }
`;

const PlanWrapper = styled.div`
  text-align: center;
`;

const PlanDiv = styled.div`
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
  font-weight: 600;
  font-size: 2.6rem;
  text-transform: uppercase;
`;

const Price = styled.div`
  font-size: 1.8rem;
  font-weight: 900;
  margin-top: 0.5rem;
  display: inline-block;
  padding: 0.5rem 2rem;

  span {
    font-size: 1rem;
    font-weight: 400;
    font-family: ${({ theme }) => theme.config.fonts.PTSans};
  }
`;

const Image = styled.img`
  display: block;
  margin: 2rem auto 0;
  max-width: 90%;
`;

const PlanBox = styled.div`
  cursor: pointer;
  border: solid 0.1rem;
  border-radius: ${({ theme }) => theme.config.defaultBorderRadius};
  border-color: ${({ theme }) => theme.palette.grey['A400']};
  width: 126px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 1rem 2rem;
  padding: 2rem;
  font-weight: 900;

  &:hover {
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const YourPackage: FC<YourPackageProps> = ({ plan, plans, teamPlan, isMobile, company }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Wrapper isMobile={isMobile} plan={plan}>
      <Title>{t('app.pro.pages.myAccount.yourPackage')}</Title>
      <PlanWrapper>
        <SvgIcon
          icon={plan === PlanUid.PRO ? Icons.HELMET_SOLID : Icons.QUOTE}
          color={plan === PlanUid.PRO ? 'gradient' : theme.palette.grey['A400']}
        />
        <div>{'Homeproved'}</div>
        <PlanDiv>{plan}</PlanDiv>
      </PlanWrapper>
      {plan === PlanUid.FREE && (
        <>
          <Box p={2} display="flex" fontWeight="900" justifyContent="center">
            Upgrade naar:
          </Box>
          <Box display="flex" justifyContent="center">
            <PlanBox>
              <SvgIcon icon={Icons.GEAR} color={'gradient'} />
              <Box mt={1} display="flex" justifyContent="center" width={'100%'}>
                BASIC
              </Box>
              <Price>
                <span>{t('app.pro.pages.upgradeModal.perMonth')}</span>
              </Price>
            </PlanBox>
            <PlanBox>
              <SvgIcon icon={Icons.HELMET_SOLID} color={'gradient'} />
              <Box mt={1} display="flex" justifyContent="center" width={'100%'}>
                PRO
              </Box>
              <Price>
                <span>{t('app.pro.pages.upgradeModal.perMonth')}</span>
              </Price>
            </PlanBox>
          </Box>
        </>
      )}
      {plan === PlanUid.BASIC && (
        <PlanBox>
          <SvgIcon icon={Icons.HELMET_SOLID} color={'gradient'} />
          <Box mt={1} display="flex" justifyContent="center" width={'100%'}>
            PRO
          </Box>
          <Price>
            <span>{t('app.pro.pages.upgradeModal.perMonth')}</span>
          </Price>
        </PlanBox>
      )}
      <Image src={'./team-plan.png'} alt="" />
    </Wrapper>
  );
};
