import React, { useEffect } from 'react';
import { Homepage } from '@homeproved/com/feature-homepage';
import { PageWithAuthorization } from '@homeproved/shared/feature-auth';
import { parseCookies } from 'nookies';
import { apiFactory, CompaniesApiFactory, ReviewsApiFactory } from '@homeproved/shared/data-access';
import { useRouter } from 'next/router';

export const Index: PageWithAuthorization = ({ companyCount, reviewCount }) => {
  const router = useRouter();
  const { cat_ID, 'offrea-sector': OffreaSector } = router.query;
  useEffect(() => {
    if (cat_ID != null || OffreaSector != null) {
      router.replace('/', undefined, { shallow: true }).then();
    }
  }, [cat_ID, router, OffreaSector]);
  return <Homepage companyCount={companyCount as number} reviewCount={reviewCount as number} />;
};

export async function getServerSideProps(context) {
  const cookies = parseCookies(context);

  let totalReviews = null;
  let totalCompanies = null;
  const reviewsApi = apiFactory(context.locale, ReviewsApiFactory);
  await reviewsApi
    .apiReviewCounterGet()
    .then((result) => {
      totalReviews = result;
    })
    .catch((error) => {
      console.log(error);
    });
  const companiesApi = apiFactory(context.locale, CompaniesApiFactory);
  await companiesApi
    .apiCompaniesCounterGet()
    .then((result) => {
      totalCompanies = result;
    })
    .catch((error) => {
      console.log(error);
    });

  if (context.query.s != null) {
    return {
      redirect: {
        destination: `/nl/vakman-zoeken?query=${context.query.s}`,
        permanent: false,
      },
    };
  }

  if (context.req.originalUrl === '/' && cookies.i18next) {
    context.res.writeHead(303, { Location: `/${cookies.i18next}` });
    context.res.end();
  }

  const formatCount = (count: number) => {
    if (!count) return 0;
    const roundedValue = Math.ceil(count / 10000) * 10000;
    return roundedValue.toLocaleString().replace(',', '.');
  };

  return {
    props: {
      companyCount: formatCount(totalCompanies?.data?.count) ?? 0,
      reviewCount: formatCount(totalReviews?.data?.count) ?? 0,
      meta: {
        title: 'meta.titles.homePage',
        description: 'meta.descriptions.homePage',
        variables: {
          '%X_REVIEWS%': totalReviews?.data?.count ?? '',
          '%X_COMPANIES%': totalCompanies?.data?.count ?? '',
        },
      },
    },
  };
}

export default Index;
