import React, { FC } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import { TestimonialSlide } from './TestimonialSlide';
import { useTranslation } from 'react-i18next';

const Wrapper = styled(({ mobile, ...restProps }) => <div {...restProps} />)`
  .slick-slider {
    padding-bottom: 3rem;
  }
  .slick-dots li {
    button:before {
      content: '';
      border-radius: 50%;
      background-color: ${({ theme, mobile }) => (mobile ? '#fff' : theme.palette.grey['200'])};
      opacity: 1;
      width: 1.3rem;
      height: 1.3rem;
    }
    &.slick-active button:before {
      background: ${({ theme }) => theme.config.gradients.default};
    }
  }
`;

type TestimonialCarrouselProps = {
  isMobile?: boolean;
};

const settings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  dots: true,
  autoplay: true,
  autoplaySpeed: 5000,
  focusOnSelect: true,
  infinite: true,
};

export const TestimonialCarrousel: FC<TestimonialCarrouselProps> = ({ isMobile }) => {
  const { t } = useTranslation();

  return (
    <Wrapper mobile={isMobile}>
      <Slider {...settings}>
        {[1, 1, 1, 1, 1].map((e, index) => (
          <TestimonialSlide
            title={t(`app.com.pages.landing.testimonials.reviews.${index}.title`)}
            text={t(`app.com.pages.landing.testimonials.reviews.${index}.text`)}
            author={t(`app.com.pages.landing.testimonials.reviews.${index}.author`)}
            key={`static-review-${index}`}
          />
        ))}
      </Slider>
    </Wrapper>
  );
};
