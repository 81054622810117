import React, { FC } from 'react';
import styled from 'styled-components';
import { Button } from '@homeproved/shared/ui';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { HomepageContainerWrapper } from '../container/Container';
import { GetPathFunction } from '@homeproved/shared/feature-localized-routes';
import { useCurrentLanguage } from '@homeproved/shared/feature-i18n';

type CallToActionProps = {
  getPath: GetPathFunction;
};

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 5rem;
`;

const Text = styled(({ isTablet, ...restProps }) => <div {...restProps} />)`
  padding: ${({ isTablet }) => (isTablet ? '6rem 2rem 2rem' : '10rem 2rem 2rem')};
  text-align: ${({ isTablet }) => (isTablet ? 'center' : 'left')};
  max-width: 50rem;
  h3 {
    margin-top: 0;
  }
`;

export const CallToAction: FC<CallToActionProps> = ({ getPath }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1185));

  const currentLanguage = useCurrentLanguage();

  return (
    <HomepageContainerWrapper>
      <Wrapper>
        <img src={`/image_homepage${isTablet ? '_mobile' : ''}-${currentLanguage}.png`} alt="" />
        <Text isTablet={isTablet}>
          <h3>{t('app.com.pages.landing.cta.header')}</h3>
          <p>{t('app.com.pages.landing.cta.body')}</p>
          <Button size="large" href={getPath('/write-review')}>
            {t('app.com.pages.landing.cta.button')}
          </Button>
        </Text>
      </Wrapper>
    </HomepageContainerWrapper>
  );
};
