import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { HomepageContainerWrapper } from '../container/Container';
import { SearchSuggestions } from '@homeproved/com/feature-search';
import { Sector } from '@homeproved/shared/data-access';
import { Inner } from '@homeproved/pro/feature-homepage';
import { Box } from '@material-ui/core';
import Image from 'next/image';

type HomepagePrimarySectionProps = {
  tablet?: boolean;
  mobile?: boolean;
  companyCount?: number;
  reviewCount?: number;
  sectors: Sector[] | null;
};

const SearchWrapper = styled(({ ...restProps }) => <div {...restProps} />)`
  padding-top: 2rem;
  max-width: 55rem;
  align-self: center;
`;

const Bubble2 = styled(({ ...restProps }) => <Box {...restProps} />)`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 146px;
  height: 146px;
  top: 168px;
  right: 60px;
  border-radius: 90px 100px 90px 10px;
  background: #fff;
  color: #000;
  z-index: 1;
  font-weight: 700;
  font-family: ${({ theme }) => theme.config.fonts.Cabrito};
`;

const Bubble1 = styled(({ ...restProps }) => <Box {...restProps} />)`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 180px;
  top: 184px;
  right: 220px;
  border-radius: 100px 90px 10px 90px;
  background: ${({ theme }) => theme.config.gradients.default};
  color: #fff;
  z-index: 1;
  font-weight: 700;
  font-family: ${({ theme }) => theme.config.fonts.Cabrito};
`;

const SearchTitle = styled(({ mobile, ...restProps }) => <div {...restProps} />)`
  font-weight: 700;
  text-shadow: 1px 1px 2px black;
  font-size: ${({ mobile }) => (mobile ? '5rem' : '6rem')};
  color: #fff;
  line-height: 6rem;
  margin-bottom: 1rem;
  font-family: SFPRO, sans-serif;
  text-align: ${({ mobile }) => (mobile ? 'center' : 'left')};
`;
const SearchSubTitle = styled(({ mobile, ...restProps }) => <div {...restProps} />)`
  font-weight: 700;
  text-shadow: 1px 1px 2px black;
  font-size: ${({ mobile }) => (mobile ? '2rem' : '2.5rem')};
  color: #fff;
  font-family: SFPRO, sans-serif;
  margin-bottom: 8rem;
  text-align: ${({ mobile }) => (mobile ? 'center' : 'left')};
`;

export const HomepagePrimarySection: FC<HomepagePrimarySectionProps> = ({
  mobile = false,
  tablet = false,
  companyCount,
  reviewCount,
  sectors,
}) => {
  const { t } = useTranslation();

  return (
    <HomepageContainerWrapper
      height={600}
      background={
        mobile ? "url('/cover-homeproved-mobile.jpg')" : "url('/cover-homeproved-desktop.jpg')"
      }
      isMobile={tablet}
    >
      <Inner>
        <Box
          display="flex"
          fontFamily="SFPRO"
          justifyContent={tablet ? 'center' : 'start'}
          alignItems="center"
          height="100%"
        >
          <SearchWrapper>
            <SearchTitle mobile={mobile}>{t('app.com.pages.landing.primary.search')}</SearchTitle>
            <SearchSubTitle mobile={mobile}>
              {t('app.com.pages.landing.primary.subsearch')}
            </SearchSubTitle>
            <SearchSuggestions searchMode="gradient" sectors={sectors} />
          </SearchWrapper>
          {!mobile && (
            <Box color="white" ml="auto">
              <Bubble1>
                <Image src="/Group 4.svg" width={60} height={50} />
                <Box fontSize="3rem">+{reviewCount}</Box>
                <Box fontSize="2rem">{t('app.com.pages.homepage.reviews')}</Box>
              </Bubble1>
              <Bubble2>
                <Image src="/Laag_1.svg" width={46} height={41} />
                <Box fontSize="2rem">+{companyCount}</Box>
                <Box fontSize="1.5rem">{t('app.com.pages.homepage.companies')}</Box>
                <Box bottom={-25} position="absolute">
                  <Image src="/Belgium.svg" width={39} height={39} />
                  <Image src="/The Netherlands.svg" width={39} height={39} />
                </Box>
              </Bubble2>
            </Box>
          )}
        </Box>
      </Inner>
    </HomepageContainerWrapper>
  );
};
