import React, { FC, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { Categories } from './categories/Categories';
import { BottomGradient, Button } from '@homeproved/shared/ui';
import { HomepagePrimarySection } from './primary/Primary';
import { AdviceAndInspiration } from './advice-and-inspiration/AdviceAndInspiration';
import { CompaniesSpotlight } from './companies-spotlight/CompaniesSpotlight';
import { CallToAction } from './calltoaction/CallToAction';
import { ReviewSection, useSectors } from '@homeproved/shared/feature-sectors';
import { Box, CircularProgress, Divider, useMediaQuery, useTheme } from '@material-ui/core';
import { Header } from '@homeproved/com/feature-header';
import { Footer } from '@homeproved/com/feature-footer';
import { TagsAndSocials } from './footer/TagsAndSocials';
import { useLocalizedRoutes } from '@homeproved/com/feature-localized-routes';
import { CompanySection } from './companies-spotlight/CompanySection';
import Image from 'next/image';
import { useIntersectionObserver } from 'usehooks-ts';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  position: relative;
  overflow-x: hidden;
  background: #fff;
`;

const Inner = styled(({ mobile, ...restProps }) => <div {...restProps} />)`
  position: relative;
  padding: ${({ mobile }) => (mobile ? '0' : '0 4rem')};
  max-width: 144rem;
  margin: auto;
`;

const Bottom = styled(({ tablet, ...restProps }) => <div {...restProps} />)`
  position: relative;
  overflow: hidden;
  margin-top: ${({ tablet }) => (!tablet ? '4rem' : 0)};
`;

const Top = styled(({ isMobile, ...restProps }) => <div {...restProps} />)`
  position: relative;
  background: ${({ theme, isMobile }) =>
    isMobile ? theme.config.gradients.default : 'transparent'};
  margin-bottom: ${({ isMobile }) => (isMobile ? 0 : '4rem')};
`;

const InfoBox = styled(({ mobile, ...restProps }) => <Box {...restProps} />)`
  background: ${({ theme }) => theme.config.gradients.default};
  align-items: center;
  padding: 4rem;
  display: flex;
  justify-content: space-around;
  gap: 8rem;
  color: #fff;
  flex-wrap: wrap;
  margin-bottom: ${({ mobile }) => (mobile ? '2rem' : '4rem')};
  text-align: ${({ mobile }) => (mobile ? 'center' : 'left')};
  border-radius: ${({ mobile }) => (mobile ? '0' : '1.5rem')};
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(359deg);
  }`;

const Loader = styled(({ mobile, loaded, ...restProps }) => <Box {...restProps} />)`
  color: #fff;
  position: relative;
  font-size: ${({ mobile }) => (mobile ? '4rem' : '5rem')};
  font-weight: 900;
  font-family: ${({ theme }) => theme.config.fonts.Cabrito};
  width: ${({ mobile }) => (mobile ? '150px' : '180px')};
  height: ${({ mobile }) => (mobile ? '150px' : '180px')};
  img {
    animation: ${spin} 2s linear ${({ loaded }) => (!loaded ? 'infinite' : '0s')};
  }
  span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const CircularProgressVariant = styled(({ ...restProps }) => <CircularProgress {...restProps} />)`
  svg {
    color: #fff;
  }
`;

interface HomePageProps {
  companyCount: number;
  reviewCount: number;
}

const settings = {
  className: 'center',
  centerMode: false,
  infinite: true,
  align: 'center',
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  focusOnSelect: true,
  arrows: false,
  autoplay: true,
  repeat: true,
  autoplaySpeed: 6000,
  responsive: [
    {
      breakpoint: 730,
      settings: {
        slidesToShow: 1,
        arrows: false,
      },
    },
  ],
};

export const Homepage: FC<HomePageProps> = ({ companyCount, reviewCount }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const isFooterDesktop = useMediaQuery(theme.breakpoints.down(1185));
  const { getPath } = useLocalizedRoutes();
  const sectors = useSectors();
  const { t } = useTranslation();

  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, { threshold: 0.5 });
  const isVisible = !!entry?.isIntersecting;

  const [infoCounter, setInfoCounter] = React.useState(0);
  const [infoCounterLoaded, setInfoCounterLoaded] = React.useState(false);

  useEffect(() => {
    if (isVisible) {
      const interval = setInterval(() => {
        setInfoCounter((prev) => {
          if (prev >= 78) {
            clearInterval(interval);
            setInfoCounterLoaded(true);
            return 78;
          }
          return prev + 4;
        });
      }, 10);
      return () => clearInterval(interval);
    } else {
      setInfoCounter(0);
      setInfoCounterLoaded(false);
    }
  }, [isVisible]);

  return (
    <Wrapper>
      <Top isMobile={isMobile}>
        <Box>
          <Header homepage />
          <Box
            p={1}
            fontSize="15px"
            height={36}
            maxWidth={'120rem'}
            width="100%"
            bgcolor="white"
            fontFamily="SFPRO"
            margin="auto"
          >
            <Slider {...settings}>
              <Box textAlign="center">
                <Image width={20} height={15} src="/Primary.svg" />
                {t('app.com.pages.homepage.info1')}
              </Box>
              <Box textAlign="center">
                <Image width={20} height={15} src="/Primary.svg" />
                {t('app.com.pages.homepage.info2')}
              </Box>
              <Box textAlign="center">
                <Image width={20} height={15} src="/Primary.svg" />
                {t('app.com.pages.homepage.info3')}
              </Box>
            </Slider>
          </Box>
        </Box>
        <HomepagePrimarySection
          companyCount={companyCount}
          reviewCount={reviewCount}
          tablet={isTablet}
          mobile={isMobile}
          sectors={sectors?.data}
        />
      </Top>
      {isMobile && (
        <Box display="flex" p={2} alignItems="center" justifyContent="space-evenly">
          <Box p={2} display="flex" flexDirection="column">
            <Image src="/Group 4 (1).svg" width={45} height={40} />
            <Box fontWeight="bold" fontFamily="SFPRO" fontSize="3rem">
              +{reviewCount}
            </Box>
            <Box textAlign="center" fontFamily="SFPRO" fontSize="1.5rem">
              {t('app.com.pages.homepage.reviews')}
            </Box>
          </Box>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Box p={2} display="flex" alignItems="center" flexDirection="column">
            <Box display="flex" justifyContent="space-around">
              <Image src="/Belgium.svg" width={40} height={40} />
              <Image src="/The Netherlands.svg" width={40} height={40} />
            </Box>
            <Box fontWeight="bold" fontFamily="SFPRO" fontSize="3rem">
              +{companyCount}
            </Box>
            <Box textAlign="center" fontFamily="SFPRO" fontSize="1.5rem">
              {t('app.com.pages.homepage.companies')}
            </Box>
          </Box>
        </Box>
      )}
      <Inner mobile={isMobile}>
        <ReviewSection
          isMobile={isMobile}
          getPath={getPath}
          transparentBG
          generalTitle
          bordered
          noTopSpacing
        />
        <InfoBox mobile={isMobile}>
          <Loader loaded={infoCounterLoaded} mobile={isMobile}>
            <div ref={ref} />
            <Box position="relative" display="inline-flex">
              <CircularProgressVariant size={'16rem'} variant="determinate" value={infoCounter} />
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {infoCounter}%
              </Box>
            </Box>
          </Loader>
          <Box
            maxWidth={isMobile ? '300px' : '700px'}
            justifyContent="center"
            display="flex"
            flexDirection="column"
          >
            <Box fontWeight="900" fontFamily="SFPRO" fontSize={isMobile ? '2rem' : '2.5rem'} mb={2}>
              {t('app.com.pages.homepage.primaryTitle')}
            </Box>
            <Box fontSize={isMobile ? '1.5rem' : '1.7rem'} fontFamily="SFPRO">
              {t('app.com.pages.homepage.primarySubTitle')}
              <Box mt={2}>
                <Button variant="white" href={getPath('/company-search')}>
                  <span>{t('app.com.pages.homepage.primaryCta')}</span>
                </Button>
              </Box>
            </Box>
          </Box>
        </InfoBox>
        <Categories sectors={sectors?.data} />
        <CompanySection getPath={getPath} />
        {/*{isMobile ? <TestimonialsMobile /> : <Testimonials />}*/}
        <CompaniesSpotlight getPath={getPath} />
        <AdviceAndInspiration getPath={getPath} />
      </Inner>
      <Bottom tablet={isTablet}>
        <CallToAction getPath={getPath} />
        <TagsAndSocials isMobile={isMobile} isTablet={isFooterDesktop} />
        <Footer transparent={!isFooterDesktop} marginTop={5} />
        {!isFooterDesktop && <BottomGradient />}
      </Bottom>
    </Wrapper>
  );
};
